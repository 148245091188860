<template>
    <Content :title="title">
        <template v-slot:content>
            <div class="container d-flex">
                <div class="layout justify-center">
                    <div class="flex mt-6 md10 xs12">
                        <v-card class="pb-10 mb-10">
                            <!--<v-img coverImage>-->
                            <v-card-text class="text--primary body-1">
                                <template v-for="(content, i) in article.content">
                                    <div v-if="content.image && !content.image_alignment"
                                        :key="`${i})solo_image`"
                                        class="mb-2 text-center"
                                    >
                                        <v-img contain class="d-inline-block ma-auto" :src="`${content.image}`"></v-img>
                                    </div>

                                    <v-layout :key="`${i}_content`" wrap>
                                        <v-flex v-if="hasLeftImage(content)" xs12 sm3>
                                            <v-img contain class="d-inline-block" :src="`${content.image}`"></v-img>
                                        </v-flex>

                                        <v-flex v-if="content.text" xs12 :class="{ 'sm9' : content.image, 'pl-4': hasLeftImage(content) && !$vuetify.breakpoint.xs }">
                                            <p v-html="content.text" :class="i === article.content.length - 1 && !$vuetify.breakpoint.xs ? 'mb-0' : ''"></p>
                                        </v-flex>

                                        <v-flex v-if="hasRightImage(content)" xs12 sm3>
                                            <v-img contain class="d-inline-block" :src="`${content.image}`"></v-img>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </div>
        </template>
    </Content>
</template>

<script>
import Content from '@/views/Content.vue'

export default {
    name: 'AboutDetails',
    components: {
        Content
    },
    props: {
        id: String
    },
    data () {
        return { }
    },
    computed: {
        title () {
            return this.article ? this.article.title : '...'
        },
        article () {
            return this.$store.getters['about/getArticle'](this.id);
        }
    },
    methods: {
        hasLeftImage (content) {
            return content.image && content.image_alignment === 'left';
        },
        hasRightImage (content) {
            return content.image && content.image_alignment === 'right';
        }
    },
}
</script>